<template>
  <div class="label-list">
    <el-form :inline="true" class="form-content">
		
      <el-form-item >

       <el-input
          placeholder="输入模型名称关键字查询"
          v-model="title"
          class="input-with-select"
          style="width: 400px"
        >
		 <el-select style="width: 100px;" v-model="select" slot="prepend" placeholder="请选择">
		    <el-option
		      :value="1"
		      label="视频观看"
		    ></el-option>
			<el-option
			  :value="2"
			  label="文字朗读"
			></el-option>
			 
		    </el-select>
          <el-button
            slot="append"
            @click="getList"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </el-form-item>

    </el-form>
    <div class="search-list">
      <el-button type="primary" @click="add">模型注册</el-button>
      <el-button
        type="danger"
        @click="deleteHandle"
        :disabled="dataListSelections.length ? false : true"
        >删除</el-button
      >
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column width="250" prop="modelNumber" label="编号"></el-table-column>

     
        <el-table-column  label="模型名称">
			<template slot-scope="scope">
				 <el-button type="text" @click="gotoInfo(scope.row)">{{scope.row.modelName}}</el-button>
			</template>
		</el-table-column>
  <el-table-column width="200"  label="注册时间">
	  <template slot-scope="scope">
	  	<div style="padding: 11px;">{{scope.row.createTime}}</div>
	  </template>
  </el-table-column>
    <el-table-column width="200" prop="createName" label="累计运行次数"></el-table-column>
	  <el-table-column width="200" prop="createName" label="状态">
		  
		  <template slot-scope="scope">
		    <span v-if="scope.row.status==1"> 启用</span> 
			  <span v-if="scope.row.status==2"> 禁用</span> 
		  </template>
	  </el-table-column>

        <el-table-column width="200" label="操作" header-align="right" align="right">
          <template slot-scope="scope">
            <el-button type="text" @click="add(scope.row)" v-if="scope.row.status==1||scope.row.status==2">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    
  </div>
</template>
<script>
import Qs from "qs";
import moment from "moment";
export default {
  name: "label",
  data() {
    return {
      title: "",
	  select: "",
      status: "",
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateRangeTime: [],
      teamsManage: [],
      typeList: [
       
      ],
      visible: false,
      form: {
        title: "",
      },
      dataRules: {
        title: [{ required: true, message: "请输入任务主题", trigger: "blur" }],
      },
      edit: false,
      flag: true, //防止重复点击
      visible2: false,
      dataList2: [],
      dataListLoading2: true, //列表loading
      dataListSelections2: [], //选中的列
      pageNo2: 1,
      pageSize2: 10,
      totalPage2: 0, //总页数
    };
  },
  async mounted() {
	      await this.getTypeList();
    await this.getList();
  },
  methods: {
    async getList() {

      let params = {

        stringParam1: this.title,
		stringParam2:this.select,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };

      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/diagnosisModel/list"),
        method: "post",
        data: params,
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
      });
      if (res.status) {
        this.dataList = res.data;
        this.totalPage = res.totalCount;
		this.dataList.forEach(item=>{
			item.createTime = moment(new Date(item.createTime)).format('YYYY-MM-DD HH:mm:ss')

		})
      }
      this.dataListLoading = false;
    },
	gotoInfo(item) {

let routeData = this.$router.resolve({
       name: "modelInfo",
       query: { id: item.id },
     });
             window.open(routeData.href, '_blank');
    },
    add(item) {
      if (item && item.id) {
        // this.form = JSON.parse(JSON.stringify(item));
     
		 let routeData = this.$router.resolve({
          name: "addModel",
          query: { id: item.id },
        });
		      window.open(routeData.href, '_blank');
      } else {
		
       let routeData = this.$router.resolve({
         name: "addModel",
       });
             window.open(routeData.href, '_blank');
      }

    },
    cancel() {
      this.$refs["form"].resetFields();
      this.visible = false;
      this.form = {
        title: "",
      };
    },
    async submit() {
      let params = {
        title: this.form.title,
        // status: 1,
      };
      if (localStorage.currentTeam) {
        params.teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      if (this.form.id) {
        params.id = this.form.id;
      }
      // console.log(params);
      this.$refs.form.validate(async (valid) => {
        if (this.flag) {
          this.flag = false;
          if (valid) {
            const { data: res } = await this.$http({
              url: this.$http.adornUrl(
                this.form.id ? "/mbglLabelIndex/update" : "/mbglLabelIndex/add"
              ),
              method: "post",
              data: Qs.stringify(params),
            });
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.flag = true;
                  this.visible = false;
                  this.getList();
                  if (res.data && res.data) {
                    this.$router.push({
                      name: "addCaseSearch",
                      query: { id: res.data },
                    });
                  }
                },
              });
            } else {
              this.$message.error(res.msg);
              this.flag = true;
            }
          } else {
            return false;
          }
        }
      });
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除分组]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          let str= '';
          if (ids.length) {
            str = ids.join(',')
          }
          let params = { ids: str, status: 2 };
          const id = ids.join(`,`);
          const { data: res } = await this.$http({
            url: this.$http.adornUrl('/mbglLabelIndex/updateStatusByIds'),
            method: "post",
            data: Qs.stringify(params),
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getList();
              },
            });
          }
        })
        .catch(() => {});
    },
   async getTypeList() {
     let params = {
       stringParam1: "病种",
     };
     // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
     const { data: res } = await this.$httpAes({
       url: this.$httpAes.adornUrl("/dict/list"),
       method: "post",
       data: params,
   	contentType:'json',
   	headers: {
   		'Content-Type': 'application/json',
   	   				
   	},
     });
     // console.log(res,'res')
     this.typeList = res.data;
   },

   async deleteHandle(item) {
      let that = this;
      let ids = null;
      if (item && item.id) {
        ids = item.id;
      } else {
        ids = that.dataListSelections.map((item) => {
          return item.id;
        });
        ids = ids.join(",");
      }
      that
        .$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
			
			that
			  .$httpAes({
			    url: this.$httpAes.adornUrl("/diagnosisModel/delete"),
			    method: "post",
			     data: { stringParam1: ids },
				contentType:'json',
				headers: {
					'Content-Type': 'application/json',
				   				
				},
			  })
			  .then(({ data }) => {
			    if (data.status) {
			      that.$message({
			        message: data.msg,
			        type: "success",
			        duration: 1500,
			        onClose: () => {
			           that.getList();
			        },
			      });
			    } else {
			      that.$message.error(data.msg);
			    }
			  });
			
		
	
        })
        .catch(() => {});
    },
    reset() {
      this.labelName = "";
      this.labelType = "";
      this.dateRangeTime = [];
      this.isGrouping = "";
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 显示下载列表
    downloadData(row) {
      this.visible2 = true;
      this.getDataList(row.id);
    },
    // 下载内容
    async getDataList(id) {
      let params = {
        pageSize: this.pageSize2,
        pageNo: this.pageNo2,
        retrievalId: id,
      };
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/mbglRetrieveRecords/list"),
        method: "get",
        params: params,
      });
      if (res.status) {
        this.dataList2 = res.data;
        this.totalPage2 = res.totalCount;
      }
      this.dataListLoading2 = false;
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getDataList();
    },
    // 添加检索
    addQuestionSearch(row) {
      let params = { retrievalId: row.id };
      this.$http({
        url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
        method: "post",
        data: Qs.stringify(params),
      }).then(({ data }) => {
        if (data.status) {
          this.updateStatus(row.id);
        }
      });
    },
    updateStatus(id) {
      let params = { ids: id, status: 3 };
      this.$http({
        url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
        method: "post",
        data: Qs.stringify(params),
      }).then(({ data }) => {
        if (data.status) {
          this.$message({
            message: "重新检索成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$router.push({
                name: "caseSearch",
              });
            },
          });
        }
      });
    },
    // 下载
    downloadUrl(row) {
      var that = this;
      let link = document.createElement("a");
      link.href = row.url;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
	.el-select .el-input {
	    width: 130px;
	  }
</style>
